






















































































































































import useEvent from "@/use/event";
import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MEventDelete: () =>
      import("@/components/molecules/event/m-event-delete.vue"),
  },
  setup(_, { root }) {
    const {
      getEventTypeColor,
      getEventTypeName,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
    } = useEvent({ root });

    const type = computed(() => root.$route.query.type);

    const { eventStatusItems, eventTypeItems } = useSelectItems({ root });

    const state = reactive({
      search: "",
      loading: false,
      empty: false,
      total: 0,
      items: [],
      loaded: false,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["startDate"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      status: "",
      nowDate: "",
      defaultSort: "startDate",
      headers: computed(() => [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        {
          text: root.$t("layout.event.list.name"),
          value: "name",
          sortable: false,
        },
        {
          text: root.$t("layout.event.list.type"),
          value: "type",
          sortable: false,
          align: "center",
        },
        {
          text: root.$t("layout.event.list.startDate"),
          value: "startDate",
          align: "center",
        },
        {
          text: root.$t("layout.event.list.endDate"),
          value: "endDate",
          align: "center",
        },
        {
          text: root.$t("layout.event.list.status"),
          value: "status",
          sortable: false,
          align: "right",
        },
        { text: "", value: "website", sortable: false, align: "center" },
        { text: "", value: "messages", sortable: false, align: "center" },
        { text: "", value: "members", sortable: false, align: "center" },
        {
          text: "",
          value: "registration",
          sortable: false,
          align: "center",
          width: 1,
        },
        { text: "", value: "coordinator", sortable: false, align: "center" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ]),
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("event", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            status: state.status || undefined,
            type: type.value || undefined,
            search: state.search || undefined,
          },
        })
        .then(({ data: { events, total } }) => {
          state.empty = false;
          state.items = events.map((event: any, index: number) => ({
            id: index,
            name: "",
            ...event,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    watch(() => type.value, fetch);
    watch(() => state.search, fetch);
    watch(() => state.status, fetch);
    watch(() => state.options, fetch, { deep: true });
    watch(
      () => state.status,
      () => {
        if (state.status === "incoming") {
          state.options.sortDesc = [false];
        } else {
          state.options.sortDesc = [true];
        }
      }
    );
    onMounted(fetch);

    const validateType = () => {
      const typeExists =
        Object.values(eventTypeItems).filter(
          (type) => type.value === root.$route.query.type
        ).length > 0;
      if (!typeExists) root.$router.push({ query: { type: "" } });
    };

    watch(() => type.value, validateType);
    onMounted(validateType);

    const onRowClick = (event: MouseEvent, data: { item: { id: string } }) => {
      root.$router.push({
        name: "panel.event.view.info",
        params: { id: data.item.id },
      });
    };

    const nowDate = () => {
      state.nowDate = new Date().toISOString().slice(0, 10);
    };

    onMounted(nowDate);

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    return {
      type,
      state,
      eventStatusItems,
      eventTypeItems,
      getEventTypeColor,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
      getEventTypeName,
      onRowClick,
      hasAccessTo,
      fetch,
    };
  },
});
