var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('layout.event.list.title')))]),_c('v-spacer'),_c('v-select',{staticClass:"ml-4 shrink",attrs:{"solo":"","dense":"","flat":"","background-color":"grey lighten-4","hide-details":"","label":_vm.$t('layout.misc.typeFilter'),"value":_vm.$route.query.type,"items":_vm.eventTypeItems,"item-text":"name"},on:{"change":function (v) { return _vm.$router.push({ query: { type: v }}); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getEventTypeColor(item.value)}},[_vm._v(_vm._s(item.name))])]}}])}),_c('v-select',{staticClass:"ml-4 shrink",attrs:{"solo":"","dense":"","flat":"","hide-details":"","background-color":"grey lighten-4","label":_vm.$t('layout.misc.statusFilter'),"items":_vm.eventStatusItems,"item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getEventStatusColor(item.value)}},[_vm._v(_vm._s(_vm.getEventStatusName(item.value)))])]}}]),model:{value:(_vm.state.status),callback:function ($$v) {_vm.$set(_vm.state, "status", $$v)},expression:"state.status"}}),_c('v-text-field',{staticClass:"shrink ml-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('layout.misc.search'),"solo":"","dense":"","flat":"","single-line":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.state.search),callback:function ($$v) {_vm.$set(_vm.state, "search", $$v)},expression:"state.search"}}),(_vm.hasAccessTo('admin'))?_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","dark":"","color":"green","to":{ name: 'panel.event.add' },"exact":""}},[_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(_vm._s(_vm.$t('layout.event.add')))]),_c('span',{staticClass:"d-md-none"},[_vm._v(_vm._s(_vm.$t('layout.event.addShort')))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),(!_vm.state.loaded)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] },"sort-by":_vm.state.defaultSort,"sort-desc":false},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:sortBy":function($event){return _vm.$set(_vm.state, "defaultSort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.state, "defaultSort", $event)},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"header.website",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-earth")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.event.website.tooltip')))])])]},proxy:true},{key:"header.messages",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-email")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.event.alerts.tooltip')))])])]},proxy:true},{key:"header.members",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-multiple")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.event.users.tooltip')))])])]},proxy:true},{key:"header.registration",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-plus")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.event.registration.tooltip')))])])]},proxy:true},{key:"header.coordinator",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-key")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.event.registration.coordinator')))])])]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[(item.siemensPrefix)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.siemensPrefix)+" -")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.prefix)+")")])]),(item.wordpressWebsite)?_c('span',{staticClass:"caption"},[_vm._v("["+_vm._s(item.wordpressWebsite.domain)+"]")]):_vm._e()])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getEventTypeColor(item.type)}},[_vm._v(_vm._s(_vm.getEventTypeName(item.type)))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [(item.startDate)?[_vm._v(_vm._s(_vm._f("moment")(item.startDate,'DD.MM.YYYY')))]:_vm._e()]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(item.endDate)?[_vm._v(_vm._s(_vm._f("moment")(item.endDate,'DD.MM.YYYY')))]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getEventStatusColor(_vm.getEventStatus(item.startDate, item.endDate))}},[_vm._v(_vm._s(_vm.getEventStatusName(_vm.getEventStatus(item.startDate, item.endDate))))])]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [(item.wordpressWebsite)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.wordpressWebsite.domain))])]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(item.participants)+" / "+_vm._s(item.amount))]):_c('span',[_vm._v(_vm._s(item.participants)+" / ∞")])]}},{key:"item.messages",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.alerts))]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
return [((item.participants < ((item.amount === null || 0) ? 1000000 : item.amount)) && (new Date(item.endRegistrationDate) >= new Date(_vm.state.nowDate)))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-variant-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.registrationOpen')))])]):((item.participants >= ((item.amount === null || 0) ? 1000000 : item.amount)) && (new Date(item.endRegistrationDate) >= new Date(_vm.state.nowDate)))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-variant-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.registrationOnReserveList')))])]):(new Date(item.endRegistrationDate) < new Date(_vm.state.nowDate))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.registrationClosed')))])]):_vm._e()]}},{key:"item.coordinator",fn:function(ref){
var item = ref.item;
return [(item.users.length > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary","size":"32"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.users.length > 0 ? item.users[0].firstName[0] : '')+_vm._s(item.users.length > 0 ? item.users[0].lastName[0] : ''))])])]}}],null,true)},_vm._l((item.users),function(user){return _c('span',{key:user.id,staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))])])}),0):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('m-event-delete',{attrs:{"event":item},on:{"eventDeleted":function () { return _vm.fetch(); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }